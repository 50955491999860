import { useState, useEffect, useRef } from "react";
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import {getDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Toast } from 'primereact/toast';
import './mycalendar.css';

function MyCalendar() {
    
    const [userInfo, setUserInfo] = useState({hour: '', id: new Date().getTime().toString()});
    const [formCompiled, setFormCompiled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const hours = [
        { name: '09:00 - 10:00', code: '09:00 - 10:00' },
        { name: '10:00 - 11:00', code: '10:00 - 11:00' },
        { name: '11:00 - 12:00', code: '11:00 - 12:00' },
        { name: '12:00 - 13:00', code: '12:00 - 13:00' },
        { name: '14:00 - 15:00', code: '14:00 - 15:00' },
        { name: '15:00 - 16:00', code: '15:00 - 16:00' },
        { name: '16:00 - 17:00', code: '16:00 - 17:00' },
        { name: '17:00 - 18:00', code: '17:00 - 18:00' },
    ];

    addLocale('it', {
        firstDayOfWeek: 1,
        dayNames: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'G', 'V', 'S'],
        monthNames: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
        monthNamesShort: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
        today: 'Oggi',
        clear: 'Cancella'
    });

    const toast = useRef(null);

    const showToast = () => {
        toast.current.show({ severity: 'info', summary: 'Perfetto!', detail: 'La tua richiesta è stata inviata correttamente' });
    };

    useEffect(()=> {
        if (userInfo.name && userInfo.lastName && userInfo.email && userInfo.date) {
            setFormCompiled(true);
        } else {
            setFormCompiled(false);
        }
    }, [userInfo])

    const sendAppointment = ()=> {
        setIsLoading(true);
        saveAppointment();
        setTimeout(() => {
            setIsLoading(false);
            reload();
        }, 3500);
    }

    const reload = () => {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }

    const saveAppointment = ()=> {
        const docRef = doc(db, "appuntamenti", userInfo.id);
        setDoc(docRef, userInfo)
        .then(()=>{showToast()}) 
    }

    return (
        <div className={isMobile ? "p-155 appointment w-100" : "p-2 appointment_desktop w-100"}>
            {
                isLoading ? 
                <ProgressSpinner className="loader"/>
                :
                <div></div>
            }
            <div className="mw-500">
                <div className="mb-15 app_txt">
                    <div className="ontario fs-24">
                        Prendi un appuntamento
                    </div>
                    <div className="fs-12">
                        <div>
                            Anche il dolore ha il suo giorno migliore, il giorno in cui si è pronti a lasciarlo andare.
                        </div>
                    </div>
                </div>
                <div className={isMobile ? "app_card" : "app_card bck_trasparent"}>
                    <div className="i-row mb-15 w-100">
                        <span className="p-float-label mr-025 w-50">
                            <InputText id="name" className="w-100" onChange={(e) => setUserInfo({...userInfo, name: e.target.value})} />
                            <label className="fs-12" htmlFor="name">Nome</label>
                        </span>
                        <span className="p-float-label ml-025 w-50">
                            <InputText id="lastName" className="w-100" onChange={(e) => setUserInfo({...userInfo, lastName: e.target.value})} />
                            <label className="fs-12" htmlFor="lastName">Cognome</label>
                        </span>
                    </div>
                    <div className="i-row mb-05">
                        <span className="p-float-label w-100">
                            <InputText id="lastName" className="w-100" onChange={(e) => setUserInfo({...userInfo, email: e.target.value})} />
                            <label className="fs-12" htmlFor="lastName">Email</label>
                        </span>
                    </div>
                    <div className="w-100">
                        <label className="label fs-12" htmlFor="calendar">Scegli il giorno</label>
                    </div>
                    <Calendar id="calendar" className="w-100 mb-05" locale="it" onChange={(e) => setUserInfo({...userInfo, date: e.target.value.toString()})} inline/>
                    <Dropdown value={userInfo.hour} onChange={(e) => setUserInfo({...userInfo, hour: e.target.value})} options={hours} optionLabel="name" 
                    placeholder="Seleziona oraio" className="w-full md:w-14rem mb-05 w-100 fs-12" />
                    <div>
                        <Button disabled={!formCompiled} label="Invia" severity="info" raised onClick={sendAppointment}/>
                        {
                            !formCompiled ? 
                            <div className="w-100">
                                <label className="label fs-12" htmlFor="calendar">Compila tutti i campi</label>
                            </div>
                            :
                            <div></div>
                        }
                    </div>
                </div>
                <Toast ref={toast} position="bottom-center"/>
            </div>
        </div>
    )
}

export default MyCalendar;