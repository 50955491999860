import './aboutme.css';
import profile from '../../assets/profile.png';
import  { useState } from 'react';

function Aboutme() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    return (
        <div className='w-100'>
            {
                isMobile ? 
                <div className='desc_container column p-mobile'>
                    <div className='p-2 w-100 desc_txt ontario mt-3 fs-32'>
                        Alice Berardo
                    </div>
                    <div className='w-100'>
                        <img src={profile} alt="profile" className='w-100'/>
                    </div>
                </div>
                :
                <div className='desc_container_desk j-s-b'>
                    <div className='w-50'>
                        <img src={profile} alt="profile" className='profile'/>
                    </div>
                    <div className='p-7 w-50 desc_txt ontario fs-64'>
                        Alice
                        <br />
                        Berardo
                    </div>
                </div>
            }
            <div className={isMobile ? '' : 'second_container'}>
                <div className={isMobile ? 'chisono_container fs-12' : 'chisono_container mh-355 fs-12 w-33'}>
                    <div className='ontario'>Chi sono:</div>
                    <div className='plr-2 descr'>
                        Sono la Dott.ssa Alice Berardo, psicologa di orientamento sistemico-relazionale iscritta all'albo A dell'Ordine degli Psicologi del Piemonte.
                        <br />
                        Ho uno studio privato, dove esercito la mia attività professionale dal 2021 in zona Crocetta, a Torino, occupandomi di supporto psicologico rivolto ad adulti, ragazzi e bambini, attraverso interventi individuali, di coppia e familiari.
                    </div>
                </div>
                <div className={isMobile ? 'formazione_container fs-12' : 'formazione_container mh-355 fs-12 w-33'}>
                    <div className='ontario'>La mia formazione:</div>
                    <div className='plr-2 descr'>
                        Ho completato la mia formazione presso l'Università di Roma in Psicologia Clinica e, successivamente al tirocinio formativo obbligatorio e l'Esame di Stato, ho intrapreso la Scuola di Psicoterapia sistemico-relazionale presso l'Istituto Emmeci di Torino.
                        <br />
                        Nel 2022 ho concluso il Master “La relazione che cura”, ampliando la possibilità di fornire supporto psicologico non solo individuale ma anche di coppia e familiare.
                        <br />
                        Durante lo stesso anno, ho frequentato diversi corsi:
                        <br />
                        - “Vivere il dolore cronico: interventi psicologici in un'ottica psicosociale”;
                        <br />
                        - “Percorsi oncologici e percorsi psiconcologici s'intrecciano negli adolescenti e nei giovani adulti”;
                        <br />
                        - “Dai BES alle EES ai DSA: il contributo dello psicologo”.
                        <br />
                        Durante il 2023 ho effettuato inoltre il Corso di I e II  livello di EMDR (Eye Movement Desensitization and Reprocessing), tecnica utile alla desensibilizzazione ed elaborazione di traumi e stress psicologici.
                    </div>
                </div>
                <div className={isMobile ? 'esperienze_container fs-12' : 'esperienze_container mh-355 fs-12 w-33'}>
                    <div className='ontario'>Esperienze professionali:</div>
                    <div className='plr-2 descr'>
                        Dal 2019 al 2021  ho lavorato nel servizio educativo-psicologico territoriale, in collaborazione con il Comune di Torino, fornendo supporto alle famiglie con figli affetti da disabilità fisiche e/o cognitive.
                        <br />
                        Dal 2021 presto servizio di supporto psicologico presso A.O.U. Città della Salute e delle Scienza di Torino, in particolare a pazienti che stanno affrontano la fase del fine vita ed i loro caregiver, oltre che a persone affette da dolore cronico con comorbidità psicologiche.
                        <br />
                        Dal 2022 mi occupo  inoltre dello “Sportello d'ascolto psicologico” della Pro Loco, rivolto ad utenti adulti con difficoltà di varia natura, e gestisco lo “Sportello d'ascolto psicologico” anche nel contesto scolastico, in scuole primarie e secondarie di I e II grado, fornendo supporto ai bambini e ai ragazzi, ai docenti e ai genitori ed attuando laboratori sull'educazione affettiva e gestione emotiva.
                        <br />
                        Nel 2023, ho portato la mia esperienza come professionista sanitaria presso diverse aziende, divenendo formatrice sulla “Salute psicologica nel contesto lavorativo”, utile al miglioramento della qualità di vita nel sistema lavorativo di appartenenza.
                        <br />
                        Da settembre dello stesso anno, ho inoltre preso parte al progetto ”Officine della Salute” in collaborazione con la Croce Rossa di Nichelino, occupandomi del sostegno psicologico di utenza appartenente a differenti fasce d'età e con diverse psicopatologie.
                    </div>
                </div>
            </div>
            <div className='fs-12 p-2'>
                <div className='ontario'>Orientamento sistemico-relazionale: Di cosa si tratta?</div>
                <div className='plr-2'>
                    L'orientamento sistemico-relazionale spiega il comportamento della persona focalizzando l'attenzione sull'ambiente in cui esso è vissuto e vive attualmente, sul sistema di cui fa parte e sulla sua rete di relazioni significative.
                    <br />
                    La persona è considerata come strettamente interconnessa con le relazioni di riferimento e con le esperienze che ha esperito nel corso della sua vita; le difficoltà o i sintomi non sono definiti quindi come una caratteristiche insite dell'individuo e non lo etichettano né lo identificano, ma piuttosto come conseguenza di esperienze relazionali.
                    <br />
                    Il percorso psicologico è quindi un'esperienza trasformativa che avviene grazie alla graduale presa di consapevolezza di sé e delle proprie dinamiche relazionali, emotive e comportamentali. L'obiettivo è quello di migliorare il benessere psicologico e la qualità di vita di chi sente di stare vivendo un periodo difficile, attraverso empatia, comprensione ed ascolto attivo e non giudicante.
                </div>
            </div>
        </div>
    )
}

export default Aboutme;