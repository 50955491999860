const Content = {
    ansia: {
        title: "DISTURBI D'ANSIA",
        content: <div>
                    <div>L'ansia è una reazione fisiologica del nostro organismo che si manifesta in seguito alla percezione di uno stimolo, esterno o interno, ritenuto minaccioso.</div>
                    <div>È quella che si definisce una “reazione adattiva”, ovvero utile al nostro organismo per proteggersi da eventi potenzialmente dannosi.</div>
                    <div>Tuttavia può capitare che tale attivazione appaia anche in situazioni che non rappresentano realmente un pericolo: questa sensazione può divenire invalidante, renderci poco lucidi e non permetterci di gestire le emozioni.</div>
                    <div>I sintomi si presentano sia a livello organico che cognitivo e tra i più comuni troviamo:</div>
                    <ul>
                        <li>Palpitazioni accompagnate talvolta da dolore toracico;</li>
                        <li>Sudorazione eccessiva e/o brividi di freddo;</li>
                        <li>Nausea e disturbi addominali;</li>
                        <li>Tremori, vertigini e svenimenti;</li>
                        <li>Derealizzazione e depersonalizzazione.</li>
                    </ul>
                    <div>A causa dell'ansia, si possono inoltre manifestare gli “attacchi di panico”, ovvero episodi di intensa paura causati da una rapida escalation della reazione ansiosa. Questo può condurre la persona all'evitamento delle situazioni ansiogene e divenire invalidante.</div>
                    <div>Se ti capita di avere “attacchi di panico” o un'ansia che non sai come gestire, puoi prendere in considerazione di iniziare un percorso. Il cambiamento che aspetti, sei semplicemente tu.</div>
                </div>
    },
    depressione: {
        title: "DEPRESSIONE",
        content: <div>
                    <div>La maggior parte di noi passa periodi in cui si sente triste, apatico o privo di interesse; i sintomi depressivi lievi sono una normale risposta ai molti fattori stressanti della vita.</div>
                    <div>La depressione diventa un disturbo solo quando i sintomi sono così gravi da interferire con il funzionamento normale e persistono per giorni, settimane o anche mesi; è caratterizzata da una forma di pensiero definita ruminazione, che rende i pensieri persistenti, circolari e depressivi, con una modalità di rilettura passiva e ripetitiva sui sintomi della depressione stessa e sulle loro possibili cause o conseguenze.</div>
                    <div>È un Disturbo dell'umore che colpisce la persona nella sua globalità, influenzando le funzioni corporee, i comportamenti, i pensieri e le emozioni.</div>
                    <div>La depressione presenta alcune caratteristiche sintomatologiche peculiari:</div>
                    <ul>
                        <li>Sintomi emotivi: tristezza percepita come dolore e disperazione intensi, anedonia (incapacità nel provare piacere in attività che prima erano considerate piacevoli);</li>
                        <li>Sintomi cognitivi: immagine negativa di sé con tematiche di inutilità, disperazione, scarsa capacità di concentrazione e memoria, confusione, senso di colpa e idee sucidarie;</li>
                        <li>Sintomi fisici: modificazioni dell'appetito e del sonno, senso di fatica, aumento di malessere e dolori vari, perdita di energia;</li>
                        <li>Sintomi motivazionali: passività, incapacità di iniziare o portare avanti delle attività.</li>
                    </ul>
                </div>
    },
    disturbialimentari: {
        title: "DISTURBI DEL COMPORTAMENTO ALIMENTARE",
        content: <div>
                    <div>I disturbi del comportamento alimentare (DCA) o disturbi dell'alimentazione sono patologie caratterizzate da una alterazione delle abitudini alimentari e da un'eccessiva preoccupazione per il peso e per le forme del corpo che insorgono prevalentemente durante l'adolescenza.</div>
                    <div>I DCA sono malattie complesse che coinvolgono fattori biologici, psicologici e socioculturali e si presentano in diverse forme, tra cui:</div>
                    <ul>
                        <li>L'anoressia nervosa che è caratterizzata da una restrizione alimentare estrema, con una paura intensa di guadagnare peso e una percezione distorta del proprio peso e della propria forma corporea.</li>
                        <li>La bulimia nervosa che si manifesta attraverso episodi ricorrenti di abbuffate seguiti da comportamenti compensatori, come il vomito autoindotto o l'uso di lassativi o diuretici.</li>
                        <li>Il disturbo da alimentazione incontrollata (BED) che è caratterizzato da episodi regolari di abbuffate senza comportamenti compensatori, associati alla sensazione di perdere il controllo.</li>
                    </ul>
                    <div>Se non trattati in tempi e con metodi adeguati, i disturbi dell'alimentazione possono diventare una condizione permanente e compromettere seriamente la salute di tutti gli organi e apparati del corpo (cardiovascolare, gastrointestinale, endocrino, ematologico, scheletrico, sistema nervoso centrale, dermatologico ecc.).</div>
                </div>
    },
    disturbidipersonalita: {
        title: "DISTURBI DI PERSONALITA'",
        content: <div>
                    <div>Per personalità si intende l'insieme di caratteristiche che ognuno di noi possiede e che definiscono la persona nella sua individualità.</div>
                    <div>I disturbi di personalità sono dei modelli duraturi di comportamenti non adattivi e quindi rigidi e poco utili al funzionamento della persona, tanto da compromettere il suo modo di pensare e relazionarsi con sé stesso e con l'ambiente circostante.</div>
                    <div>Queste esperienze disadattive, hanno spesso inizio durante l'infanzia e permangono nel tempo: le emozioni, i pensieri ed i comportamenti sperimentati dalla persona sono molto diversi a seconda del disturbo specifico di cui soffrono.</div>
                    <div>Tra i disturbi di personalità troviamo:</div>
                    <ul>
                        <li>Personalità paranoide: caratterizzata da diffidenza e sospettosità verso gli altri, ai quali tende ad attribuire cattive intenzioni; teme di venire danneggiata o ingannata, anche a fronte di mancanza di prove concrete.</li>
                        <li>Personalità borderline: presenta uno schema di instabilità nei rapporti personali, emozioni intense e scarsa capacità di regolarle, scarsa autostima e impulsività, senso cronico di vuoto e solitudine; una visione di sé e dell'altro che può passare velocemente da rappresentazioni opposte e scarsamente integrate;</li>
                        <li>Personalità narcisistica: caratterizzata da senso di superiorità, bisogno di ammirazione e mancanza di empatia per gli altri, considerandoli come un mezzo per arrivare al proprio scopo; sono sensibili al fallimento e alla critica che può suscitare loro intensa rabbia ma anche indurre stati depressivi.</li>
                        <li>Personalità antisociale: ignora o viola i diritti degli altri, non dà valore alla norma sociale e usa l'altro per raggiungere i propri scopi (a differenza della personalità narcisistica lo sfruttamento dell'altro è puramente utilitaristico e non giustificato dalla propria presunta superiorità); può mentire ripetutamente, ingannare gli altri e agire impulsivamente.</li>
                        <li>Personalità evitante: caratterizzata da timidezza, sentimenti di inadeguatezza ed estrema sensibilità alle critiche; la difficoltà a stare in relazione spinge all'isolamento che però, a differenza della personalità schizoide, è vissuto con sofferenza e cela un forte desiderio di accettazione e vicinanza da parte dell'altro; critiche, rifiuti e abbandoni aumentano il ritiro sociale e, a differenza del disturbo borderline, non provocano rabbia ma vergogna e tristezza.</li>
                        <li>Personalità dipendente: caratterizzata da insicurezza e scarsa autostima, difficoltà a prendere decisioni quotidiane senza essere rassicurate dagli altri e sentimento di disagio. A causa del timore di essere incapaci di prendersi cura di sé stesse e di procurare un allontamento delle altre persone, tali individui tendono a sottomettersi all'altro, mettendo i propri bisogni e le proprie opinioni in secondo piano.</li>
                        <li>Personalità ossessivo-compulsiva: caratterizzata dalla preoccupazione per l'ordine, la perfezione e il controllo. L'intolleranza all'incertezza e all'errore rende tali individui poco flessibili e adattabili al cambiamento, oltre che estremamente rallentate nel processo decisionale. A differenza del disturbo ossessivo compulsivo non presenta pensieri ossessivi e rituali.</li>
                    </ul>
                    <div>Se non trattati in tempi e con metodi adeguati, i disturbi dell'alimentazione possono diventare una condizione permanente e compromettere seriamente la salute di tutti gli organi e apparati del corpo (cardiovascolare, gastrointestinale, endocrino, ematologico, scheletrico, sistema nervoso centrale, dermatologico ecc.).</div>
                </div>
    },
    disturbipsicosomatici: {
        title: "DISTURBI PSICOSOMATICI",
        content: <div>
                    <div>L'imbarazzo fa arrossire, la paura fa sudare, l'ansia fa rallentare la salivazione. Questi sono solo alcuni esempi che mostrano chiaramente come corpo, emozioni e sentimenti siano strettamente collegati tra loro.</div>
                    <div>‍Il corpo ci parla, dando voce a ciò che la nostra mente non riesce ad accettare o comunicare liberamente, trasformandolo in malessere fisico e generando così sintomi psicosomatici.</div>
                    <div>I sintomi psicosomatici appaiono quando l'organismo è sottoposto ad una condizione di emergenza e/o a emozioni inespresse o irrisolte. Il problema nasce quando i livelli di ansia o stress superano una certa soglia o perdurano per lungo tempo, senza la possibilità di essere gestiti efficacemente.</div>
                    <div>I disturbi psicosomatici maggiormente diffusi sono:</div>
                    <ul>
                        <li>Disturbi dell'apparato gastrointestinale: nausea, meteorismo, vomito, diarrea, colite, ulcera, gastrite. Alcuni degli organi più sensibili alle "emozioni indigeste" sono infatti lo stomaco e tutto l'apparato digerente;</li>
                        <li>Disturbi dell'apparato cardiocircolatorio: aritmia, ipertensione, tachicardia, senso di svenimento;</li>
                        <li>Disturbi dell'apparato urogenitale: dolori e irregolarità mestruali, disfunzioni erettili o dell'eiaculazione, frequente impulso ad urinare;</li>
                        <li>Disturbi della pelle: acne, psoriasi, dermatite, prurito, orticaria, secchezza cutanea e delle mucose, eccessiva sudorazione notturna da ansia;</li>
                        <li>Disturbi neuromuscolari: cefalea o emicrania, formicolii, rigiDità e dolori muscolari, paralisi, crampi, debolezza, tremore da ansia, sbandamenti e vertigini.</li>
                    </ul>
                </div>
    },
    stress: {
        title: "STRESS",
        content: <div>
                    <div>Lo stress è la risposta psicofisica ad una quantità di compiti emotivi, cognitivi o sociali percepiti dalla persona come eccessivi.</div>
                    <div>Il processo stressogeno si compone di tre fasi distinte:</div>
                    <ul>
                        <li>Fase di allarme: il soggetto segnala l'esubero di doveri e mette in moto le risorse per adempierli;</li>
                        <li>Fase di resistenza: il soggetto stabilizza le sue condizioni e si adatta al nuovo tenore di richieste;</li>
                        <li>Fase di esaurimento: in questa fase si registra la caduta delle difese e la successiva comparsa di sintomi fisici ed emotivi.</li>
                    </ul>
                    <div>La durata dell'evento stressante porta a distinguere lo stress in due categorie. Quello acuto, che si verifica una sola volta e in un lasso di tempo limitato e quello cronico, cioè quando lo stimolo è di lunga durata.</div>
                    <div>Può essere provocato da:</div>
                    <ul>
                        <li>Eventi della vita paranormativi;</li>
                        <li>Fattori ambientali: ambienti rumorosi o inquinati;</li>
                        <li>Malattie organiche: l'organismo, nel tentativo di difendersi, si pone in uno stato di tensione che può sfociare in una condizione di stress.</li>
                    </ul>
                </div>
    },
    fobie: {
        title: "FOBIE",
        content: <div>
                    <div>Una fobia è la paura opprimente e debilitante sviluppata nei confronti di un oggetto, un luogo o una situazione.</div>
                    <div>Le fobie, che rientrano nei disturbi d'ansia, sono più pronunciate delle paure e si sviluppano quando una persona avverte un senso di pericolo eccessivo rispetto alla situazione vissuta.</div>
                    <div>Le fobie possono essere suddivise in due categorie principali:</div>
                    <ul>
                        <li>Fobie specifiche o semplici: riguardano un particolare oggetto, animale, situazione o attività; spesso si sviluppano durante l'infanzia o l'adolescenza e possono diventare meno gravi con l'età.</li>
                        <li>Fobie complesse: tendono ad essere limitanti e a svilupparsi durante l'età adulta; sono spesso associate a una paura, o un'ansia, profondamente radicata in relazione a una specifica situazione.</li>
                    </ul>
                </div>
    },
}

export default Content;