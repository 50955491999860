import React from 'react';
import logo from './logo.svg';
import MyCalendar from './components/calendar/myclendar';
import NavBar from './components/navbar/navbar';
import Home from './components/homepage/homepage';
import Admin from './components/admin/admin';
import './App.css';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; 
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";  
import Aboutme from './components/aboutme/aboutme';
import AreeIntervento from './components/areeIntervento/areeIntervento';
import Footer from './components/footer/footer';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Router>
          <NavBar />
          <Routes>
            <Route path={"/"} element={<Home />}/>
            <Route path={"/appuntamenti"} element={<MyCalendar />}/>
            <Route path={"/admin"} element={<Admin />}/>
            <Route path={"/chisono"} element={<Aboutme />}/>
            <Route path={"/ansia"} element={<AreeIntervento />}/>
            <Route path={"/depressione"} element={<AreeIntervento />}/>
            <Route path={"/disturbialimentari"} element={<AreeIntervento />}/>
            <Route path={"/disturbidipersonalita"} element={<AreeIntervento />}/>
            <Route path={"/disturbipsicosomatici"} element={<AreeIntervento />}/>
            <Route path={"/stress"} element={<AreeIntervento />}/>
            <Route path={"/fobie"} element={<AreeIntervento />}/>
            <Route path={"*"} element={<Home />}/>
          </Routes>
          <Footer />
        </Router>
      </header>
    </div>
  );
}

export default App;
