import { useState, useEffect } from 'react';
import { Menubar } from 'primereact/menubar';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/logo.png';
import 'primeicons/primeicons.css';
import './navbar.css';

function NavBar() {

    const navigate = useNavigate();
    const [counter, setCounter] = useState(0);
    const [showAdmin, setShowAdmin] = useState(false);

    const items = [
        {
            label: 'Chi sono',
            icon: 'pi pi-fw pi-user',
            url: '/chisono'
        },
        {
            label: 'Aree di intervento',
            icon: 'pi pi-fw pi-heart',
            items: [
                {
                    label: "Disturbi d'ansia",
                    url: '/ansia'
                },
                {
                    label: 'Depressione',
                    url: '/depressione'
                },
                {
                    label: 'Disturbi alimentari',
                    url: '/disturbialimentari'
                },
                {
                    label: 'Disturbi di personalità',
                    url: '/disturbidipersonalita'
                },
                {
                    label: 'Disturbi psicosomatici',
                    url: '/disturbipsicosomatici'
                },
                {
                    label: 'Stress',
                    url: '/stress'
                },
                {
                    label: 'Fobie',
                    url: '/fobie'
                },
            ]
        },
        {
            label: 'Appuntamenti',
            icon: 'pi pi-fw pi-calendar-minus',
            url: '/appuntamenti'
        },
        // {
        //     label: 'Contatti',
        //     icon: 'pi pi-fw pi-comment'
        // },
    ];

    const updateCounterAndGoHome = ()=> {
        let updateCounter = counter;
        setCounter(updateCounter+1);
        navigate('/')
    }

    useEffect(()=>{
        // TODO VEDERE SEMPRE QUANDO SI CAMBIANO I LINK DELLA NAVBAR
        if (counter >= 25 && !showAdmin) {
            setShowAdmin(true);
        }
    }, [counter])

    const start = <img alt="logo" src={logo} height="40" className="mr-2 pointer" onClick={updateCounterAndGoHome}></img>;

    return (
        <div className="w-100">
            <Menubar model={items} start={start}/>
            <div>
                {
                    showAdmin ? 
                    <span className="pi pi-eye" onClick={()=>{navigate('/admin')}}></span>
                    :
                    <div></div>
                }
            </div>
        </div>
    )
}

export default NavBar;