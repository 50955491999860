import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import {getDoc, doc, setDoc, getDocs, collection, deleteDoc  } from 'firebase/firestore';
import { db } from '../../firebase-config';
import { Card } from 'primereact/card';
import { ProgressSpinner } from 'primereact/progressspinner';
import './admin.css';

function Admin () {

    const [admin, setAdmin] = useState(false);
    const [openModalLogin, setOpenModalLogin] = useState(false);
    const [inputPsw, setInputPsw] = useState();
    const [appuntamenti, setAppuntamenti] = useState([]);
    const [deletedId, setDeletedID] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        if(!admin) setOpenModalLogin(true);
    }, [])

    useEffect(()=>{
        if (admin) getAppointment();
    }, [admin])

    const getAppointment = async() => {
        let appointment = []
        const app = await getDocs(collection(db, "appuntamenti"));
        app.forEach((doc) => {
            appointment.push(doc.data())
        });
        setAppuntamenti(appointment);
    }

    const checkIfIsAdmin = (e)=> {
        if (inputPsw === '123456789') {
            setAdmin(true);
            setOpenModalLogin(false)
        }
    }

    const deleteAppointment = (index)=> {
        let id = [...deletedId];
        id.push(appuntamenti[index].id);
        setDeletedID(id);
        let apps = [...appuntamenti];
        apps.splice(index, 1);
        setAppuntamenti(apps);
    }

    const save = async()=> {
        setIsLoading(true);
        deletedId.forEach(id => {
            const docRef = doc(db, "appuntamenti", id);
            deleteDoc(docRef)
            .then(() => {
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
            }) 
        });
    }

    return (
        <div className='w-100'>
            {
                isLoading ? 
                <ProgressSpinner className="loader"/>
                :
                <div></div>
            }
            {
                admin?
                <div className='w-100 app_container'>
                    <div className='ontario fs-24 mb-15 mt-15'>
                        Tutti gli appuntamenti
                    </div>
                    {
                       appuntamenti.map((a, i)=>
                       <div key={i} className="w-90 mw-700 mb-15 t-a-l">
                           <Card title={<div className='card_header'><div>{a.name + ' ' + a.lastName}</div><div onClick={()=>{deleteAppointment(i)}}><span className='pi pi-trash'></span></div></div>}>
                                <div>
                                    <div className='label mb-0'>
                                        {new Date(Number(a.id)).toLocaleDateString('IT-it')}
                                    </div>
                                    <div>
                                        <p className='label mb-0'>Email:</p>
                                        <div>{a.email}</div>
                                    </div>
                                    <div>
                                        <p className='label mb-0'>Data e ora appuntamento richiesto:</p>
                                        <div>
                                            {new Date(a.date).toLocaleDateString('IT-it')} {a.hour.code? a.hour.code : ''}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                       </div>
                       )
                    }
                    <div className='save_btn'>
                        <Button className='mt-05' label="Salva modifiche" severity="info" raised onClick={save}/>
                    </div>
                </div>
                :
                <div></div>
            }
            <Dialog header="Auth" visible={openModalLogin} style={{ width: '50vw' }} onHide={() => setOpenModalLogin(false)}>
                <div className="m-0">
                    <span className="p-float-label mt-15 w-100">
                        <InputText id="name" className="w-100" onChange={(e)=>{setInputPsw(e.target.value)}} />
                        <label className="fs-12" htmlFor="name">Psw</label>
                    </span>
                    <div className='w-100' style={{'display': 'flex', 'justifyContent': 'center'}}>
                        <Button className='mt-05' label="Verifica" severity="info" raised onClick={checkIfIsAdmin}/>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

export default Admin;