import './homepage.css';
import { useState } from 'react';
import {useNavigate} from 'react-router-dom';

function Home () {

    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    return(
        <div className="w-100 forDesktop">
            <div className={isMobile ? "first_container home_mob p-2" : "first_container home_desk p-2"}>
                <div className='mw-500'>
                    <div className={isMobile ? "app_card bck_trasparent" : "app_card bck_trasparent"}>
                        <div>
                            <h2>ALICE BERARDO</h2>
                            <div>
                                Sono la Dott.ssa Alice Berardo, psicologa di orientamento sistemico-relazionale iscritta all'albo A dell'Ordine degli Psicologi del Piemonte.
                                Ho uno studio privato, dove esercito la mia attività professionale dal 2021 in zona Crocetta, a Torino, occupandomi di supporto psicologico rivolto ad adulti, ragazzi e bambini, attraverso interventi individuali, di coppia e familiari. 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={isMobile ? 'column second_container mb-15' : 'second_container mb-15'}>
                <div className={isMobile? 'section b_o w-100 mh-265' : 'section b_o w-33 mh-350'}>
                    <h4>Chi sono</h4>
                    <div className='fs-12'>
                        <div>Ciao! Sono Alice Berardo</div>
                        <div>Psicologa di orientamento sistemico-relazionale</div>
                        <div>Iscritta all'albo A dell'Ordine degli Psicologi del Piemonte</div>
                        <div>Ho completato la mia formazione presso l'Università di Roma in Psicologia Clinica</div>
                    </div>
                    <div className='arrow' onClick={()=>{navigate('/chisono')}}>
                        <div></div>
                        <span className="pi pi-arrow-circle-right pointer"></span>
                    </div>
                </div>
                <div className={isMobile ? 'section b_two w-100 mh-265' : 'section b_two w-33 mh-350'}>
                    <h4>Aree di intervento</h4>
                    <div className='fs-12'>
                        <div>Stress</div>
                        <div>Depressione</div>
                        <div>Ansia</div>
                        <div>Disturbi alimentari</div>
                        <div>Attacchi di panico</div>
                        <div>Disturbi di personalità</div>
                        <div>Fobie</div>
                        <div>Disturbi psicosomatici</div>
                    </div>
                    <div className='arrow'>
                        {/* <div></div>
                        <span className="pi pi-arrow-circle-right pointer"></span> */}
                    </div>
                </div>
                <div className={isMobile ? 'section b_t w-100 mh-265' : 'section b_t w-33 mh-350'}>
                    <h4>Prendi appuntamento</h4>
                    <div className='fs-12'>
                        <div>Lasciami la tua mail</div>
                        <div>Scegli il giorno e l'orario a te più comodo</div>
                        <div>ti ricontatterò al più presto per confermarti l'appuntamento.</div>
                    </div>
                    <div className='arrow' onClick={()=>{navigate('/appuntamenti')}}>
                        <div></div>
                        <span className="pi pi-arrow-circle-right pointer"></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;