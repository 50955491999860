import './footer.css';
import { useState } from 'react';
import footershape from '../../assets/footershape.png';

function Footer() {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    return (
        <div className='w-100 p-2 footer_container relative'>
            <img className='shape' src={footershape} alt="" />
            <div className={isMobile ? 'w-90 ontario fs-18 dott' : 'w-50 ontario fs-18 dott'}>Dott.ssa Alice Berardo</div>
            <div className={isMobile ? 'w-100 info_container_mobile' : 'w-100 info_container'}>
                <div className={isMobile ? '' : 'w-33'}>
                    <span className='pi pi-map-marker'></span>
                    <div className='fs-12'>Corso Re Umberto 126</div>
                </div>
                <div className={isMobile ? '' : 'w-33'}>
                    <span className='pi pi-phone'></span>
                    <div className='fs-12'>3339402562</div>
                </div>
                <div className={isMobile ? '' : 'w-33'}>
                    <span className='pi pi-inbox'></span>
                    <div className='fs-12'>psi.alice.berardo@gmail.com</div>
                </div>
            </div>
        </div>
    )
}

export default Footer;