import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBvP0C4FyN8TfoKuw1lr-IutvthXOphZt8",
  authDomain: "a-b-psicologa.firebaseapp.com",
  projectId: "a-b-psicologa",
  storageBucket: "a-b-psicologa.appspot.com",
  messagingSenderId: "905737296588",
  appId: "1:905737296588:web:ece2c4ba6b3946293a0069",
  measurementId: "G-XTP87TMY66"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app)
const analytics = getAnalytics(app);

export {db}