import './areeIntervento.css';
import {useState, useEffect} from 'react';
import Content from '../../const/areeInterventoContent';
import {useNavigate} from 'react-router-dom';
import ansiaImg from '../../assets/ansia.png';
import depressioneImg from '../../assets/depressione.png';
import disturbialimentariImg from '../../assets/disturbialimentari.png';
import disturbipersonalitaImg from '../../assets/disturbipersonalita.png';
import disturbipsicomaticiImg from '../../assets/disturbipsicosomatici.png';
import fobieImg from '../../assets/fobie.png';
import stressImg from '../../assets/stress.png';

function AreeIntervento () {

    const navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [data, setData] = useState();
    const [img, setImg] = useState();

    useEffect(()=>{
        switch(window.location.pathname) {
            case '/ansia':
                setData(Content.ansia);
                setImg(ansiaImg);
            break;
            case '/depressione':
                setData(Content.depressione);
                setImg(depressioneImg);
            break;
            case '/disturbialimentari':
                setData(Content.disturbialimentari);
                setImg(disturbialimentariImg);
            break;
            case '/disturbidipersonalita':
                setData(Content.disturbidipersonalita);
                setImg(disturbipersonalitaImg);
            break;
            case '/disturbipsicosomatici':
                setData(Content.disturbipsicosomatici);
                setImg(disturbipsicomaticiImg);
            break;
            case '/stress':
                setData(Content.stress);
                setImg(stressImg);
            break;
            case '/fobie':
                setData(Content.fobie);
                setImg(fobieImg);
            break;
        }
    },[])

    return (
        <div>
            {
                data ? 
                <div className='centerize'>
                    <h1 className='mb-0 ontario'>{data.title}</h1>
                    <img className='w-90 mw-700' src={img} alt={data.title} />
                    <div className={isMobile ? 'content fs-12' : 'content fs-18'}>{data.content}</div>
                    <div className='mt-15 mb-15 relative app' onClick={()=>{navigate('/appuntamenti')}}>
                        <div className='ontario fs-32 plr-2'>Prendi appuntamento</div>
                        <span className='pi pi-external-link'></span>
                    </div>
                </div>
                :
                <div></div>
            }
            
        </div>
    )
}

export default AreeIntervento;